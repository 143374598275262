import { registerLocaleData } from "@angular/common";
import localePt from "@angular/common/locales/pt";
import localeEs from "@angular/common/locales/es";
import localeDe from "@angular/common/locales/de";
import localeFr from "@angular/common/locales/fr";
import localeIt from "@angular/common/locales/it";
import localeTr from "@angular/common/locales/tr";
import localeZh from "@angular/common/locales/zh";

export const setupLocales = () => {
	registerLocaleData(localePt, 'pt-BR');
	registerLocaleData(localeEs, 'es-ES');
	registerLocaleData(localeDe, 'de-DE');
	registerLocaleData(localeFr, 'fr-FR');
	registerLocaleData(localeIt, 'it-IT');
	registerLocaleData(localeTr, 'tr-TR');
	registerLocaleData(localeZh, 'zh-CN');
}
