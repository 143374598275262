'use strict'

// set self in server
void !function(){
    typeof self === 'undefined' && typeof global === 'object' ? global.self = global : null;
}();

self.firebaseOptions = {
    config: {
        apiKey: "AIzaSyD0orfq8-EKsLrDDMfJ4lGJgU-SgScrzx4",
        authDomain: "joinin-5800f.firebaseapp.com",
        projectId: "joinin-5800f",
        storageBucket: "joinin-5800f.appspot.com",
        messagingSenderId: "124416276932",
        appId: "1:124416276932:web:b38af3eb12855ec108cc91",
        measurementId: "G-K4XH39TB8G"
    },
    db: {
        name: 'notifications-firebase-cloud-messaging',
        version: 1,
        storeName: 'notifications-clicked',
        storeConfig: {
            autoIncrement: true
        }
    }
};

Object.freeze(self.firebaseOptions);
Object.freeze(self.firebaseOptions.config);
Object.freeze(self.firebaseOptions.db);
