import { Translate } from "src/app/shared/helpers/translate";
import { LANGUAGE_LOADERS } from "../app/languages/static/language-loaders";
import { LanguageHelpers } from "../app/shared/helpers/language-helpers";
import { Generic } from "src/app/shared/models/generic";

export const getTranslation = async (lang: string) => {
	const allPaths = LANGUAGE_LOADERS as Generic;

	const loadTranslation = allPaths?.[lang] ?? LANGUAGE_LOADERS["pt-BR"];

	const result = await loadTranslation();

	return result.TRANSLATIONS;
};

export const chooseTranslation = async () => {
	let lang = LanguageHelpers.getApplicationLanguage();

	let TRANSLATIONS: Generic;

	try {
		TRANSLATIONS = await getTranslation(lang);
	} catch (error) {
		lang = "pt-BR";
		TRANSLATIONS = await getTranslation(lang);
	}

	localStorage.setItem("language", lang);
	Translate.configurations.defaultLanguage = lang;
	Translate.setInitialTranslations(TRANSLATIONS);
};
