export const LANGUAGE_LOADERS = {
	"pt-BR": () => import("../pt-BR/pt-br-translate"),
	"es-ES": () => import("../es-ES/es-es-translate"),
	"en-US": () => import("../en-US/en-us-translate"),
	"de-DE": () => import("../de-DE/de-de-translate"),
	"fr-FR": () => import("../fr-FR/fr-fr-translate"),
	"it-IT": () => import("../it-IT/it-it-translate"),
	"tr-TR": () => import("../tr-TR/tr-tr-translate"),
	"zh-CN": () => import("../zh-CN/zh-cn-translate"),
} as const;

export type LanguageLocale = keyof typeof LANGUAGE_LOADERS;
