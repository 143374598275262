import '../firebase-options.js';
import { Authentication } from "../app/core/authentication/authentication";

export const LOAD_ENV = async () => {
	const resp = await fetch('assets/config/config.json')
		.then((response) => response.json());

	const env2 = {
		api: resp.apiUrl,
		wsApi: resp.wsApi,
		hasServiceWorker: resp?.hasServiceWorker ?? true,
	};
	Object.assign(environment, env2);
};

export const environment = {
    // @ts-ignore
    firebaseOptions: self?.firebaseOptions,
    appVersion: require('../../package.json').version,
    production: true,
    sandbox: false,
    staging: false,
    development: false,
	hasServiceWorker: true,
	api: "",
	wsApi: "",
	accountApi: 'https://auth.joinin.com.br',
	accountApi2: 'https://auth2.joinin.com.br',
    headers: Authentication.getHeaders,
    local: 'production'
};
