import { HttpHeaders, HttpResponse } from "@angular/common/http";
import { Auxiliary } from "src/app/shared/helpers/auxiliary";
import { Generic } from "src/app/shared/models/generic";
import { LocalService } from "../../shared/services/local.service";

export class Authentication {
	static nameProperty = 'SE2mK5G6c4h1NYjcbSuQRw'; //Headers criptografado com base no authkey;
	static accessToken = 'access-token';
	static propertyParameters: Generic = {
		authkey: 'b800ee0b3027bb6732130ceba67d0438',
		uid: '',
		[Authentication.accessToken]: '',
		client: '',
		platform: '1'
	};
	static headers = new HttpHeaders(Authentication.propertyParameters);

	static getHeadersAsObject(): Generic {
		return Authentication.getCurrentHeadersObjectByKeys(Authentication.headers.keys());
	}

	static isThereAccessToken(): boolean {
		Authentication.getHeaders();

		return !!Authentication.headers.get(Authentication.accessToken);
	};

	static getCurrentHeadersObjectByKeys(keys: string[]): Generic {
		const headersObject: Generic = {};

		keys.forEach(key => headersObject[key] = Authentication.headers.get(key));

		return headersObject;
	};

	static getHeaders(): HttpHeaders {
		let resp = "";

		try{
			const headers = localStorage.getItem("headers");
			if(headers){
				resp = headers
			}
			else
				resp = LocalService?.getData(Authentication.nameProperty)?.toString?.();

			if(!resp) resp = "{}"
		}
		catch (er){
			resp = "{}"
		}
		finally {
			const headersFromLocalStorage = JSON.parse(resp) as Generic;
			Authentication.setHeaders(headersFromLocalStorage);

		}

		return Authentication.headers;
	};

	static setHeaders(newHeaders: Generic = {}): HttpHeaders {
		const nextHeaders = Object.assign(Authentication.getHeadersAsObject(), newHeaders);

		for (const property in nextHeaders) {
			if (nextHeaders.hasOwnProperty(property)) {
				if (!Auxiliary.isUndefined(nextHeaders[property]) && !Auxiliary.isNull(nextHeaders[property])) {
					Authentication.headers = Authentication.headers.set(property, nextHeaders[property]);
				} else {
					Authentication.headers = Authentication.headers.delete(nextHeaders[property]);
					delete nextHeaders[property];
				}
			}
		}


		LocalService.saveData(Authentication.nameProperty, JSON.stringify(nextHeaders));
		localStorage.removeItem("headers")
		return Authentication.headers;
	};

	static createHeadersByResponse(
		response: HttpResponse<any>,
		propertyKeys = Object.keys(Authentication.propertyParameters)
	): Generic {
		const newHeaders: Generic = {};

		propertyKeys.forEach(item => {
			const value = response.headers.get(item);

			if (value) newHeaders[item] = value;
		});

		return newHeaders;
	};

	static resetHeaders(): void {
		Authentication.setHeaders({uid: '', [Authentication.accessToken]: '', client: ''});
	}
}
