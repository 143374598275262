import { LANGUAGE_LOADERS, LanguageLocale } from "../../languages/static/language-loaders";

export class LanguageHelpers {
	static switchToLanguage(language: LanguageLocale) {
		localStorage.setItem("language", language);
	}

	static getApplicationLanguage(): LanguageLocale {
		const defaultLang = "en-US";

		if(typeof window !== 'undefined'){
			const selectedLanguage = localStorage.getItem("language") as LanguageLocale | null;

			const validSelected = this.findValidLanguage(selectedLanguage ?? '');

			if(validSelected) return validSelected;

			const navigatorLanguage = this.getNavigatorLanguage();
			return navigatorLanguage ?? defaultLang;
		}

		return defaultLang;
	}

	private static availableLanguages(): LanguageLocale[] {
		return Object.keys(LANGUAGE_LOADERS) as LanguageLocale[];
	}

	private static findValidLanguage(language: string): LanguageLocale | undefined {
		return this.availableLanguages().find(available => {
			const availablePreffix = available.split('-')[0];
			const languagePreffix = language.split('-')[0];

			return availablePreffix === languagePreffix;
		});
	}

	private static getNavigatorLanguage() {
		return this.findValidLanguage(navigator.language);
	}
}
