// tslint:disable-next-line:no-duplicate-imports
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import 'moment/locale/pt-br';

export class Moment {
    static moment = _rollupMoment || _moment;


    static setNextDate(date: Date, day: number): Date{
        const curDate = new Date(date);

        curDate.setDate(curDate.getDate() + (day));

        return curDate;
    }
}
